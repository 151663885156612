import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167')
];

export const server_loads = [2];

export const dictionary = {
		"/[[region]]/(main)": [27,[2,3],[,4]],
		"/[[region]]/(main)/(static)/about": [28,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/affiliate": [29,[2,3,5],[,4]],
		"/[[region]]/auth-screen": [164,[2,24]],
		"/[[region]]/(main)/blog": [45,[2,3,8],[,4]],
		"/[[region]]/(main)/blog/[slug]": [46,[2,3,8],[,4]],
		"/[[region]]/business": [165,[2,25]],
		"/[[region]]/business/data-services": [166,[2,25]],
		"/[[region]]/(main)/(static)/cookies": [30,[2,3,5],[,4]],
		"/[[region]]/(main)/delete-account": [47,[2,3,9],[,4]],
		"/[[region]]/(main)/dennis": [48,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system": [49,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/alert-message": [50,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/bricks": [51,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/button-bar": [52,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/buttons": [53,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/cards": [54,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/checkbox-buttons": [55,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/checkbox-tags": [56,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/checkboxes-with-search": [57,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/closable": [58,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/colors": [59,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/conditional-component": [60,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/context-menu": [61,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/css-variables": [62,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/cursorphobic": [63,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/delay-render": [64,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/double-content-panel": [65,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/draggable-progress-bar": [66,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/dropdown-filter": [67,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/dual-range-slider": [68,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/empty": [69,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/error-toasts": [70,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/evaluate-search": [71,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/expandable-description": [72,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/explore": [73,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/external-partner": [74,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/fold-out": [75,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/fonts": [76,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/grid": [77,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/hero": [78,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/horizontal-list": [79,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/image-fan": [80,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/inputs": [81,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/item-content": [82,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/mixins": [83,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/modal": [84,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/my-library": [85,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/navigation": [86,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/notifications": [87,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/number-ticker": [88,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/onboarding-block": [89,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/onboarding-simple-recommendations": [90,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/playback-queue": [91,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/playback-with-progress-button": [92,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/playlinks": [93,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/playlist-cards": [94,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/playlists": [95,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/radio-buttons": [96,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/scroll-distance": [97,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/shadows": [98,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/similarity-badge": [99,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/skeleton": [100,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/spinner": [101,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/suck-into-library": [102,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/swipeable": [103,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/tabs": [104,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/takeover": [105,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/timeout-bar": [106,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/toggleable-tooltip": [107,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/toggles-with-search": [108,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/universal-dropdown": [109,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/universal-tooltip": [110,[2,3,11],[,4]],
		"/[[region]]/(main)/design-system/user-avatar": [111,[2,3,11],[,4]],
		"/[[region]]/(main)/discover-weekly": [112,[2,3],[,4]],
		"/[[region]]/(main)/downloads": [113,[2,3,12],[,4]],
		"/[[region]]/(main)/email-settings/[signed_uuid]": [114,[2,3],[,4]],
		"/[[region]]/(main)/(static)/eula": [31,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/faq": [32,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/guidelines": [33,[2,3,5],[,4]],
		"/[[region]]/(main)/invite/[username]": [115,[2,3],[,4]],
		"/[[region]]/(main)/library/(content)": [116,[2,3,13,14],[,4]],
		"/[[region]]/(main)/library/(content)/[filter]": [117,[2,3,13,14],[,4]],
		"/[[region]]/maintenance": [167,[2,26]],
		"/[[region]]/(main)/name/[sid]-[name]": [118,[2,3],[,4]],
		"/[[region]]/(main)/(static)/newsletter/unsubscribed": [~34,[2,3,5],[,4]],
		"/[[region]]/(main)/notifications": [119,[2,3],[,4]],
		"/[[region]]/(main)/partner": [120,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/api": [121,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/api/playlinks": [122,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/languages-regions": [123,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/link-in-bio": [124,[2,3,16],[,4]],
		"/[[region]]/(main)/partner/link-in-bio/edit/[[sid]]": [125,[2,3,16],[,4]],
		"/[[region]]/(main)/partner/on-page": [126,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/on-page/configuration": [127,[2,3,15],[,4]],
		"/[[region]]/(main)/partner/on-page/customization": [128,[2,3,15],[,4]],
		"/[[region]]/(main)/playlists": [130,[2,3],[,4]],
		"/[[region]]/(main)/playlist/[playlistId]": [129,[2,3],[,4]],
		"/[[region]]/(main)/podcasts": [133,[2,3,18],[,4]],
		"/[[region]]/(main)/podcasts/explore": [134,[2,3,18],[,4]],
		"/[[region]]/(main)/podcasts/explore/[topic]": [135,[2,3,18],[,4]],
		"/[[region]]/(main)/podcasts/playlists": [136,[2,3,18],[,4]],
		"/[[region]]/(main)/podcast/[sid]-[name]": [131,[2,3,17],[,4]],
		"/[[region]]/(main)/podcast/[sid]-[name]/episode/[episode_sid]-[episode_name]": [132,[2,3,17],[,4]],
		"/[[region]]/(main)/(static)/policy": [35,[2,3,5],[,4]],
		"/[[region]]/(main)/profile": [137,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/hidden": [138,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/ratings": [139,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/saved": [140,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/services": [141,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/settings": [142,[2,3,19],[,4]],
		"/[[region]]/(main)/profile/tracked": [143,[2,3,19],[,4]],
		"/[[region]]/(main)/rate-titles": [144,[2,3],[,4]],
		"/[[region]]/(main)/recommendations": [145,[2,3,20],[,4]],
		"/[[region]]/(main)/reset-password": [146,[2,3,21],[,4]],
		"/[[region]]/(main)/reviews/[slug]": [147,[2,3],[,4]],
		"/[[region]]/(main)/russia-temporarily-unavailable": [148,[2,3],[,4]],
		"/[[region]]/(main)/search": [149,[2,3],[,4]],
		"/[[region]]/(main)/similar-to/[sid]": [150,[2,3],[,4]],
		"/[[region]]/(main)/social": [151,[2,3],[,4]],
		"/[[region]]/(main)/(static)/stepler": [36,[2]],
		"/[[region]]/(main)/users": [158,[2,3,23],[,4]],
		"/[[region]]/(main)/users/follow-requests": [159,[2,3,23],[,4]],
		"/[[region]]/(main)/users/followers": [160,[2,3,23],[,4]],
		"/[[region]]/(main)/users/following": [161,[2,3,23],[,4]],
		"/[[region]]/(main)/users/similar": [162,[2,3,23],[,4]],
		"/[[region]]/(main)/users/synced-contacts": [163,[2,3,23],[,4]],
		"/[[region]]/(main)/user/uuid/[uuid]": [157,[2,3],[,4]],
		"/[[region]]/(main)/user/[username]": [152,[2,3,22],[,4]],
		"/[[region]]/(main)/user/[username]/followers": [153,[2,3,22],[,4]],
		"/[[region]]/(main)/user/[username]/following": [154,[2,3,22],[,4]],
		"/[[region]]/(main)/user/[username]/ratings": [155,[2,3],[,4]],
		"/[[region]]/(main)/user/[username]/reviews": [156,[2,3,22],[,4]],
		"/[[region]]/(main)/[category=categories]": [38,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/service/[service]": [40,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/service/[service]/[genre=genres]": [41,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/[genre=genres]": [39,[2,3,6],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]": [42,[2,3,7],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]/customize": [43,[2,3,7],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]/season-[season]": [44,[2,3,7],[,4]],
		"/[[region]]/(main)/[...path]": [37,[2,3],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';